<template><div><h1 id="disabling-write-protect-via-battery" tabindex="-1"><a class="header-anchor" href="#disabling-write-protect-via-battery"><span>Disabling Write Protect via Battery</span></a></h1>
<div class="custom-container warning"><p class="custom-container-title">WARNING</p>
<p>This method requires opening your device. Be sure to use the correct tools to prevent hardware damage.</p>
</div>
<h2 id="step-1-open-the-device-and-disconnect-the-battery" tabindex="-1"><a class="header-anchor" href="#step-1-open-the-device-and-disconnect-the-battery"><span>Step 1: Open the device and disconnect the battery</span></a></h2>
<ol>
<li>
<p>Power down your device. Close the lid and flip it over.</p>
</li>
<li>
<p>Take out all of the screws.</p>
<div class="custom-container tip"><p class="custom-container-title">TIP</p>
<p>Screws may be underneath the rubber feet that keep the Chromebook from sliding.</p>
</div>
</li>
<li>
<p>Gently pry the back off the Chromebook. If you do not have the proper tools a dull knife would be helpful.</p>
<ul>
<li>On some Chromebooks, the keyboard needs to be removed instead of the back. Be careful as there's a ribbon wire underneath that needs disconnecting first.</li>
</ul>
</li>
<li>
<p>Unplug the battery connector. You do not need to take the entire battery out of the Chromebook.</p>
</li>
</ol>
<h2 id="step-2-boot-using-ac-power-and-flash" tabindex="-1"><a class="header-anchor" href="#step-2-boot-using-ac-power-and-flash"><span>Step 2: Boot using AC power, and flash</span></a></h2>
<ol>
<li>
<p>Plug the Chromebook into a power adapter.</p>
<div class="custom-container tip"><p class="custom-container-title">TIP</p>
<p>There is a 15V USB-C PD requirement for booting without the battery.</p>
</div>
</li>
<li>
<p>Boot the chromebook up</p>
<ul>
<li>If you removed the keyboard, make sure to reattach it for this step.</li>
</ul>
</li>
<li>
<p>Continue to <RouteLink to="/docs/firmware/flashing-firmware.html">Flashing Firmware</RouteLink></p>
</li>
</ol>
</div></template>


